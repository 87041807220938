import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<StatisticsView>({})
export default class StatisticsView extends Vue {
  @Prop()
  protected items!: StatisticsItem[];

  @Prop()
  protected columns!: StatisticsColumn[];

  @Prop()
  protected options!: StatisticsOptions;

  protected defaultOptions: StatisticsOptions = {
    showTotals: true,
  }

  protected mounted() {
    this.defaultOptions = { ...this.defaultOptions, ...this.options };
  }

  protected columnTotal(columnIndex : number) {
    let total = 0;

    this.items.forEach((item: StatisticsItem) => {
      this.columns.forEach((column: StatisticsColumn, index: number) => {
        if (column.key !== 'name' && columnIndex === index && item.data) {
          total += Number((item.data as any)[column.key as any]);
        }

        if (column.key !== 'name' && columnIndex === index && ! item.data) {
          total += Number((item as any)[column.key as any]);
        }
      });
    });

    return total;
  }

  public isFunction(object: any) {
    return object && {}.toString.call(object) == '[object Function]';
  }
}

export interface StatisticsColumn {
  key: string;
  name: string;
  class: string;
  transform?: Function;
}

export interface StatisticsItem {
  [key: string]: string | number;
}

export interface StatisticsOptions {
  showTotals: boolean;
}
